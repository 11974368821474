import { Alert, AlertIcon, HStack, IconButton, Tooltip } from "@chakra-ui/react";
import * as React from "react";
import { useEffect, useState } from "react";
import { adminService } from "../../api/admin/admin.service";
import { ColorModeSwitcher } from "../../ColorModeSwitcher";
import { GrLogout } from "react-icons/all";
import { useNavigate } from "react-router-dom";

export const Header = () => {
  const navigate = useNavigate();
  const [isProd, setIsProd] = useState<boolean>(false);

  const loadStatus = async () => {
    try {
      const { data } = await adminService.isProd();
      setIsProd(data.status);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    loadStatus();
  }, []);

  return (
    <Alert status={isProd ? "error" : "info"}>
      <HStack>
        <ColorModeSwitcher pos="absolute" right={20} />
        <Tooltip label="Logout" hasArrow>
          <IconButton
            pos="absolute"
            right={7}
            onClick={() => {
              localStorage.removeItem("token");
              navigate("/");
            }}
            aria-label="Logout"
            icon={<GrLogout />}
            _dark={{ path: { stroke: "white" } }}
          />
        </Tooltip>
      </HStack>

      <AlertIcon />
      {isProd || window.location.href.includes("admin.crosswatch.info")
        ? "Is Prod Environment!!!"
        : "Is Local Environment"}
    </Alert>
  );
};
