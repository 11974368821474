import { Box, Button, Divider, Stack, useBoolean, useDisclosure, useToast } from "@chakra-ui/react";
import { usePlatforms } from "../../hooks/usePlatforms";
import { LoaderWrapper } from "../../components/ListLoaderWrapper/LoaderWrapper";
import { PlatformCardItem } from "./components/PlatformCardItem";
import {PlusSquareIcon, SmallAddIcon} from "@chakra-ui/icons";
import { platformService } from "../../api/platforms/platform.service";
import * as yup from "yup";
import { GenericFormModal } from "../../components/GenericFormModal/GenericFormModal";
import React from "react";
import { PlatformName } from "../../api/platforms/platform-names.enum";

export const PlatformsListPage = () => {
  const toast = useToast();
  const platformsData = usePlatforms();
  const createPlatformModalDisclosure = useDisclosure();

  const [createPlatformLoading, updateCreatePlatformLoading] = useBoolean(false);

  const createPlatform = async ({ name }: Record<string, string>) => {
    updateCreatePlatformLoading.on();

    try {
      await platformService.create({ name: name as PlatformName });
      platformsData.refetch();
      updateCreatePlatformLoading.off();
      createPlatformModalDisclosure.onClose();
      toast({
        title: "Store was successfully created",
        status: "success",
      });
    } catch (err) {
      toast({
        title: "Store was not created",
        status: "error",
      });
      console.log(err);
    }
  };

  return (
    <Box>
      <Button
        colorScheme={"green"}
        size={"sm"}
        leftIcon={<PlusSquareIcon />}
        onClick={createPlatformModalDisclosure.onOpen}
      >
        Create Platform
      </Button>
      <Divider orientation="horizontal" mt="2" mb="6" />
      <LoaderWrapper
        noData={platformsData.hasBeenLoaded && !platformsData.list.length}
        initialLoading={platformsData.loading && !platformsData.hasBeenLoaded}
        loading={platformsData.loading}
      >
        <Stack spacing="4">
          {platformsData.list.map((platform) => (
            <PlatformCardItem
              key={platform._id}
              platform={platform}
              onRemoveSuccessfully={platformsData.refetch}
              onRenameSuccessfully={platformsData.refetch}
            />
          ))}
        </Stack>
      </LoaderWrapper>
      <GenericFormModal
        title={"Create Platform"}
        isOpen={createPlatformModalDisclosure.isOpen}
        onClose={createPlatformModalDisclosure.onClose}
        actionLoading={createPlatformLoading}
        onSubmit={createPlatform}
        controls={[
          {
            name: "name",
            label: "Name",
          },
        ]}
        controlsValidation={yup.object().shape({
          name: yup.string().required(),
        })}
      />
    </Box>
  );
};
