import { Box, Button, Divider, Table, TableContainer, Tag, Tbody, Td, Tr, useToast } from "@chakra-ui/react";
import React, { useRef } from "react";
import { isAxiosError } from "axios";
import {DeleteIcon, PlusSquareIcon, SmallAddIcon} from "@chakra-ui/icons";
import { LoaderWrapper } from "../../components/ListLoaderWrapper/LoaderWrapper";
import { RemovePlayModeModal } from "./components/RemovePlayModeModal/RemovePlayModeModal";
import { PlayModeService } from "../../api/play-modes/play-mode.service";
import { usePlayModes } from "../../hooks/usePlayModes";
import { PlayMode } from "../../api/play-modes/play-mode.model";
import { CreatePlayModeModal } from "./components/CreatePlayModeModal/CreatePlayModeModal";

export const PlayModesListPage = () => {
  const toast = useToast();
  const playModesData = usePlayModes();

  const createPlayModeModalRefController = useRef<CreatePlayModeModal["RefController"]>(null);
  const removePlayModeModalRefController = useRef<RemovePlayModeModal["RefController"]>(null);

  const createPlayMode: CreatePlayModeModal["Props"]["onSave"] = async (data) => {
    if (!createPlayModeModalRefController.current) {
      return;
    }

    createPlayModeModalRefController.current.switchLoading(true);

    try {
      await PlayModeService.create(data);
      toast({
        title: "Play Mode was successfully created",
        status: "success",
      });

      playModesData.refetch();
      createPlayModeModalRefController.current.clear();
    } catch (err) {
      if (isAxiosError(err)) {
        if (err.response?.data.message?.includes("PLAY_MODE_ALREADY_EXISTS")) {
          toast({
            title: "PlayMode with such name already exists",
            status: "error",
          });
          return;
        }
      }

      toast({
        title: "Unexpected error. Please reload page and try again",
        status: "error",
      });
    } finally {
      createPlayModeModalRefController.current.switchLoading(false);
    }
  };

  const removePlayMode: RemovePlayModeModal["Props"]["onRemove"] = async (playMode) => {
    if (!removePlayModeModalRefController.current) {
      return;
    }

    removePlayModeModalRefController.current.switchLoading(true);

    try {
      await PlayModeService.remove(playMode._id);
      toast({
        title: "PlayMode was successfully deleted",
        status: "success",
      });

      playModesData.refetch();
      removePlayModeModalRefController.current.clear();
      removePlayModeModalRefController.current.close();
    } catch (err) {
      toast({
        title: "Unexpected error. Please reload page and try again",
        status: "error",
      });
    } finally {
      removePlayModeModalRefController.current.switchLoading(false);
    }
  };

  const handleRemoveClick = (playMode: PlayMode) => {
    if (!removePlayModeModalRefController.current) {
      return;
    }

    removePlayModeModalRefController.current.open(playMode);
  };

  return (
    <Box>
      <Button
        colorScheme={"green"}
        size={"sm"}
        leftIcon={<PlusSquareIcon />}
        onClick={() => createPlayModeModalRefController.current?.open()}
      >
        Create Play Mode
      </Button>
      <Divider orientation="horizontal" mt={2} mb="6" />
      <LoaderWrapper
        noData={playModesData.hasBeenLoaded && !playModesData.list.length}
        initialLoading={playModesData.loading && !playModesData.hasBeenLoaded}
        loading={playModesData.loading}
      >
        <TableContainer>
          <Table size="sm">
            <Tbody>
              {playModesData.list.map((playMode) => (
                <Tr key={playMode._id}>
                  <Td>
                    <Box>
                      <Tag>{playMode.name}</Tag> - <Tag>{playMode.slug}</Tag>
                    </Box>
                  </Td>
                  <Td width="5%" textAlign="center" paddingX={0}>
                    <Button leftIcon={<DeleteIcon />} colorScheme="red" onClick={() => handleRemoveClick(playMode)}>
                      Remove
                    </Button>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </LoaderWrapper>
      <CreatePlayModeModal ref={createPlayModeModalRefController} onSave={createPlayMode} />
      <RemovePlayModeModal ref={removePlayModeModalRefController} onRemove={removePlayMode} />
    </Box>
  );
};
