import { Box, Button, Divider, Flex, Stack } from "@chakra-ui/react";
import { LoaderWrapper } from "../../components/ListLoaderWrapper/LoaderWrapper";
import { Pagination } from "../../components/Pagination/Pagination";
import React from "react";
import { useArticles } from "../../hooks/useArticles";
import {Link, useNavigate} from "react-router-dom";
import { ArrowForwardIcon } from "@chakra-ui/icons";

export const ArticlesPage = () => {
  const navigate = useNavigate();
  const articlesData = useArticles();

  const handlePaginationPageChange = (skip: number) => {
    articlesData.load({ skip });
  };

  return (
    <Box>
      <LoaderWrapper
        noData={articlesData.hasBeenLoaded && !articlesData.data.entities.length}
        initialLoading={articlesData.loading && !articlesData.hasBeenLoaded}
        loading={articlesData.loading}
      >
        <Box display={"flex"} flexDirection={"column"}>
          <Stack spacing="4">
            {articlesData.data.entities.map((entity) => (
              <Box border={"1px solid"} borderColor={"gray.400"} borderRadius={2} p={2} key={entity._id}>
                <Box>{entity.title}</Box>
                <Flex mt={2} columnGap={2}>
                  <Link to={`/articles/${entity._id}`}>
                    <Button size={"xs"} rightIcon={<ArrowForwardIcon />}>
                      To Article
                    </Button>
                  </Link>
                  <Link to={`/games/${entity.belongsTo.id}`}>
                    <Button size={"xs"} rightIcon={<ArrowForwardIcon />}>
                      To Game
                    </Button>
                  </Link>
                </Flex>
              </Box>
            ))}
          </Stack>
          <Pagination
            take={articlesData.data.take}
            skip={articlesData.data.skip}
            count={articlesData.data.count}
            style={{ mx: "auto", my: 3 }}
            onPageClick={handlePaginationPageChange}
          />
        </Box>
      </LoaderWrapper>
    </Box>
  );
};
