import { Box, Button, Divider, Table, TableContainer, Tag, Tbody, Td, Tr, useToast } from "@chakra-ui/react";
import { LoaderWrapper } from "../../components/ListLoaderWrapper/LoaderWrapper";
import React, { useRef } from "react";
import { DeleteIcon, PlusSquareIcon, SmallAddIcon } from "@chakra-ui/icons";
import { isAxiosError } from "axios";
import { RemoveSocialModal } from "./components/RemoveSocialModal/RemoveSocialModal";
import { CreateSocialModal } from "./components/CreateSocialModal/CreateSocialModal";
import { useSocials } from "../../hooks/useSocials";
import { SocialService } from "../../api/socials/genre.service";
import { Social } from "../../api/socials/social.model";

export const SocialsListPage = () => {
  const toast = useToast();
  const socialsData = useSocials();

  const createSocialModalRefController = useRef<CreateSocialModal["RefController"]>(null);
  const removeSocialModalRefController = useRef<RemoveSocialModal["RefController"]>(null);

  const createGenre: CreateSocialModal["Props"]["onSave"] = async (data) => {
    if (!createSocialModalRefController.current) {
      return;
    }

    createSocialModalRefController.current.switchLoading(true);

    try {
      await SocialService.create(data);
      toast({
        title: "Social was successfully created",
        status: "success",
      });

      socialsData.refetch();
      createSocialModalRefController.current.clear();
    } catch (err) {
      if (isAxiosError(err)) {
        if (err.response?.data.message?.includes("SOCIAL_ALREADY_EXISTS")) {
          toast({
            title: "Social with such name already exists",
            status: "error",
          });
          return;
        }
      }

      toast({
        title: "Unexpected error. Please reload page and try again",
        status: "error",
      });
    } finally {
      createSocialModalRefController.current.switchLoading(false);
    }
  };

  const removeSocial: RemoveSocialModal["Props"]["onRemove"] = async (social) => {
    if (!removeSocialModalRefController.current) {
      return;
    }

    removeSocialModalRefController.current.switchLoading(true);

    try {
      await SocialService.remove(social._id);
      toast({
        title: "Social was successfully deleted",
        status: "success",
      });

      socialsData.refetch();
      removeSocialModalRefController.current.clear();
      removeSocialModalRefController.current.close();
    } catch (err) {
      toast({
        title: "Unexpected error. Please reload page and try again",
        status: "error",
      });
    } finally {
      removeSocialModalRefController.current.switchLoading(false);
    }
  };

  const handleRemoveClick = (social: Social) => {
    if (!removeSocialModalRefController.current) {
      return;
    }

    removeSocialModalRefController.current.open(social);
  };

  return (
    <Box>
      <Button
        colorScheme={"green"}
        size={"sm"}
        leftIcon={<PlusSquareIcon />}
        onClick={() => createSocialModalRefController.current?.open()}
      >
        Create Social
      </Button>
      <Divider orientation="horizontal" mt={2} mb="6" />
      <LoaderWrapper
        noData={socialsData.hasBeenLoaded && !socialsData.list.length}
        initialLoading={socialsData.loading && !socialsData.hasBeenLoaded}
        loading={socialsData.loading}
      >
        <TableContainer>
          <Table size="sm">
            <Tbody>
              {socialsData.list.map((social) => (
                <Tr key={social._id}>
                  <Td>
                    <Box>
                      <Tag>{social.name}</Tag> - <Tag>{social.slug}</Tag>
                    </Box>
                  </Td>
                  <Td width="5%" textAlign="center" paddingX={0}>
                    <Button leftIcon={<DeleteIcon />} colorScheme="red" onClick={() => handleRemoveClick(social)}>
                      Remove
                    </Button>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </LoaderWrapper>
      <CreateSocialModal ref={createSocialModalRefController} onSave={createGenre} />
      <RemoveSocialModal ref={removeSocialModalRefController} onRemove={removeSocial} />
    </Box>
  );
};
