import * as React from "react";
import { ChakraProvider, Container, Box, Flex } from "@chakra-ui/react";
import { CategoryMenu, Header } from "./components";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { StoresListPage, PlatformsListPage, GamesListPage, GamePage, LoginPage } from "./pages";
import { GameUrlTabNames } from "./pages/GamePage/game-url-tab-names";
import { AppConfigCrossplayGamesStaticSortPriority } from "./pages/AppConfigCrossplayGamesSortOrder/AppConfigCrossplayGamesStaticSortPriority";
import { GenresListPage } from "./pages/GenresListPage/GenresListPage";
import { PlayModesListPage } from "./pages/PlayModesListPage/PlayModesListPage";
import { SocialsListPage } from "./pages/SocialsListPage/SocialsListPage";
import { ArticlesPage } from "./pages/ArticlesPage/ArticlesPage";
import { ArticleCreatePage } from "./pages/ArticleCreatePage/ArticleCreatePage";
import { ArticleManagePage } from "./pages/ArticleManagePage/ArticleManagePage";
import { CacheManagementPage } from "./pages/CacheManagementPage/CacheManagementPage";

export const App = () => {
  return (
    <ChakraProvider toastOptions={{ defaultOptions: { position: "top-right", isClosable: true } }}>
      <Routes>
        <Route path="/auth/login" element={<LoginPage />} />
        <Route
          path="/*"
          element={
            <Flex>
              <Box>
                <CategoryMenu />
              </Box>
              <Container maxW="container.xl">
                <Header />
                <Box mb={2} />
                <Routes>
                  <Route path="/" element={<Navigate to="/games" />} />
                  <Route path="/games" element={<GamesListPage />} />
                  <Route path="/platforms" element={<PlatformsListPage />} />
                  <Route path="/stores" element={<StoresListPage />} />
                  <Route path="/genres" element={<GenresListPage />} />
                  <Route path="/socials" element={<SocialsListPage />} />
                  <Route path="/articles" element={<ArticlesPage />} />
                  <Route path="/articles/:articleId" element={<ArticleManagePage />} />
                  <Route path="/create-article" element={<ArticleCreatePage />} />
                  <Route path="/play-modes" element={<PlayModesListPage />} />
                  <Route path="/games/:gameId" element={<Navigate to={GameUrlTabNames.BasicInfo} />} />
                  <Route path="/games/:gameId/:tab" element={<GamePage />} />
                  <Route
                    path="/app-config/crossplay/games-sort-order"
                    element={<AppConfigCrossplayGamesStaticSortPriority />}
                  />
                  <Route path="/cache-management" element={<CacheManagementPage />} />
                </Routes>
              </Container>
            </Flex>
          }
        />
      </Routes>
    </ChakraProvider>
  );
};
